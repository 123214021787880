.text{
    width:90%;
    margin:auto;
    padding:0px 0px 30px 0px;
    background-color:#ECEBDC;
}


@media only screen and (min-width: 550px) {
    .text{
        width:70%;
        margin:auto;
    }    
}