.footer{
    background-color:black;
    height:40px;
    margin-top:40px;
    width:100%;
    position:fixed;
    bottom:0; 
    z-index:2000;
}

.imgContainer {
    width:100%;
}

.imgContainer img{
    height:40px;
}

@media only screen and (max-width: 480px) {

.footer{
    height:25px;
}

.imgContainer {
    width:100%;
}

.imgContainer img{
    height:25px;
}

}