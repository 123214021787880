.input {
    border: 1px solid #999;
    padding: 8px;
    width: 250px;
    background-color: #ECEBDC;
  }
  .input:focus{
    border: 3px solid  #007662;
  }
  .noAutocomplete {
    color: #999;
    padding: 8px;
  }
  .autocomplete {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
  }
  .autocomplete li {
    padding: 8px;
  }
  .autocomplete > .active,
  .autocomplete li:hover {
    /* background-color: #48473B; */
    cursor: pointer;
    color:#007662;
    font-weight: 700;
  }
  .autocomplete li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }

  .errorMessage{
    font-size:10px;
    color:red;
  }

  .autoCompleteLabel{
    color:#007662;
  }

  .insWrap{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    margin:10px 0px;
  }

  .removeInstrument{
    color:red;
    cursor:pointer;
    margin-left:10px;
    
  }

  .error{
    font-size:10px;
      color:red;
  }