.header{
  z-index:200;
}

.header ul {
  list-style-type: none;
  display: flex;
  flex-direction: row-reverse;
  /* justify-content:flex-end; */
  margin-right: 50px;
  position: relative;
  right: 0;
  }
  
  .link a {
    padding: 5px 10px;
    display: block;
    color: black;
    text-decoration: none;
  }
  .link:hover a {
    color:#007662;
  }
  
  nav {
    width: 80%;
    position: absolute;
    margin:20px auto;
  }
  
  .link {
    float: right;
    color: black;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #031d2a; */
    margin: 0 10px;
    font-size:20px;
  
  }
  
  .header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    
    
    padding: 10px;
  }
  .logo {
    align-self: flex-start;
    margin-right: 50px;
    margin-left: 50px;
    width: 8%;
  }

  .logo img{
    width:100%;
    height:100%;
  }
  
  .dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    float: right;
    width: 140px;
    position: relative;
    /*background-color: #031d2a;*/
    margin: 0 10px;
    border-radius: 40%;
  }
  
  .dropdown p {
    margin: 0;
  }
  
  .dropdownContent {
    display: none;
    width: 100%;
    height: 200%;
    text-align: center;
    position: absolute;
    bottom: -1;
    left: 0;
    background-color: black;
  }
  .dropdown:hover .dropdownContent {
    display: inline-block;
    display: flex;
    flex-direction: column;
  }
  .dropdownContent a {
    text-decoration: none;
    color: blue;
  }
  
  .dropdownContent a:hover {
    background-color: blue;
    color: white;
  }
  
  .dropdownContent a {
    padding: 5px 10px;
    display: block;
    color: white;
  
    text-decoration: none;
  }
  .usluge:hover {
    cursor: pointer;
  }
  .link:hover p {
    color: white;
  }
  .usluge {
    color: white;
  }
  hr {
    width: 100%;
    color: white;
  }
  .bars {
    display: none;
  }
  .responsiveInvisible {
    display: none;
  }
  .list {
    display: inline-block;
  }
  
  @media only screen and (max-width: 640px) {
    .responsiveInvisible {
      display: none;
    }
    .invisible {
      display: none;
    }
    nav {
      width: 100%;
    }
    .header {
       
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .logo {
      width: 100%; 
      margin: auto;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-start;
    }
    .logo img {
      margin: auto;
      width:100%;
      height:100%;
    }
    .imgContainer {
      text-align: center;
      height:100px;
      width:100px;
    }
    .header {
       
      margin-left: 0;
      padding-left: 0;
    }
    .header ul {
      flex-direction: column-reverse;
      padding-left: 0;
      margin-right: 0;
    }
    .link {
      width: 100%;
      padding: 30px 10px;
  
      background-color: #fffcf6;
      border-radius: 0;
      padding-left: 0;
      margin-left: 0;
    }
    .link a {
      color: #002643;
      font-size: 20px;
      font-family: "Sansita";
    }
    .link:hover {
      background-color: #01518b;
    }
    .list {
      z-index: 150;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      animation-name: slidein;
      animation-duration: 1s;
    }
  
    @keyframes slidein {
      from {
        margin-left: 50%;
        width: 200%;
      }
  
      to {
        margin-left: 0%;
        width: 100%;
      }
    }
  
    .bars {
      color: white;
      display: inline-block;
      margin-left: 15px;
      z-index: 150;
      width: 40px;
      height: 40px;
    }
  
    .bars :hover {
      cursor: pointer;
    }
  
    .backdrop {
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 100;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
  
    @media only screen and (max-width: 480px) {
      .imgContainer {
        width: 50vw;
      }
      .imgContainer img {
        width: 100%;
      }
  
      .bars {
        align-self: center;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
      }
      .header ul {
        margin-top: 0;
        padding-top:0;
        z-index:200;
      }
    }
  }
  
  @media only screen and (max-width: 865px) and (min-width: 640px) {
    .link span {
      font-size: 1.7vw;
    }
    .logo {
      margin-right: 5px;
    }
    .header ul {
      margin-right: 30px;
    }
  }
  
  @media only screen and (max-width: 1050px) and (min-width: 600px) {
    .imgContainer {
      width: 20vw;
    }
    .imgContainer img {
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 700px) and (min-width: 550px) {
    .imgContainer {
      width: 25vw;
    }
    .imgContainer img {
      width: 100%;
    }
  }
  @media only screen and (max-width: 700px) {
    .bars {
      width: 10vw;
    }
  }
  @media only screen and (max-width: 550px) {
    .imgContainer {
      margin-top:5px;
      margin-right:5px;
      width: 50px;
      height: 50px;
    }
    .header {
        
      height: 60px;
    }
    .imgContainer img {
      width: 100%;
    }
  }