


.home{
    width:100vw;
    padding:0px 0px 30px 0px;
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-bottom:20px;
    justify-content:flex-start;
    /* background-image: url("/public/images/aleksa.jpg");
    background-size:contain; */
}

.card{
    width:70%;
    margin-top:20px;
    padding:10px;
    font-size:20px;
    border:solid 1px black;
    font-family:Sansita;
    height:100px;
    text-align:center;
    display:flex;
   align-items:center;
   justify-content:center;
   background-size:cover;
}
.card a{
    text-decoration:none;
    color:white;
    font-size:28px;
    
    
}

@media only screen and (min-width: 550px) {

.home{
    width:90vw;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content: space-around;
    margin:auto;
    
}

.card{
    width:25%;
    height:150px;
}

}