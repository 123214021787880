.filterButtons{
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
}

.filterButton{
    padding:5px;
    border-radius:10px;
    border: 1px solid #48473B;
    color:#48473B;
    margin:10px;
}

.filterButton:hover{
    cursor:pointer;
}

.active{
    color:#ECEBDC;
    background-color:#48473B;
}

.filterWrapper{
    min-height:100vh;
    /* background-image: url("/public/images/aleksa.jpg");
    background-size:contain; */
}

/* ACCORDION */

.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
    background-color:#ECEBDC;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
}

.accordion__button:hover {
    background-color: #ddd;
    cursor:pointer;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
    background-color:#ECEBDC;
}

.acoustic{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-bottom:50px;
    width:100vw;
    min-height:250px;
}

.cardWrap{
    width:75%;
    height:150px;
    background-size:cover;
    display:flex;
    align-items:center;
    justify-content:center;
    margin:20px auto;
    padding:1px;
    box-shadow: #48473B 0px 5px 15px;
    border-radius:10px; 
    position:relative;
    
}

.name{
    text-decoration:none;
    color:white;
    font-size:28px;
    line-height:40px;
    font-family:Sansita;
    z-index:15;
    width:100%;
    height:40px;
    background: rgba(29,46,50, 0.9) center;
}

.name:hover{
    cursor:pointer;
}

.darkenBg{
    height:100%;
    width:100%;
    background-color:grey;
    z-index:10;
    position:absolute;
    top:0;
    left:0;
    opacity:0.5;
    transition: opacity 0.4s;
}

.name:hover ~ .darkenBg{
    opacity:0.8;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (min-width: 550px) {

    .acoustic{
        width:90vw;
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        justify-content: space-around;
        margin:auto;
    }
    
    .cardWrap{
        width:25%;
        height:150px;
    }

}

