.contact {
    width: 100%;
    /* background: rgba(0, 32, 57, 0.9); */
    /* background-color: #16678f; */
    display: flex;
    flex-direction: row;
    color: black;
    align-items: center;
    justify-content: center;
    min-height:100vh;
    background-image: url("/public/images/aleksa.jpg");
  }

.bigContainer{
    width:50%;
   display:flex;
   flex-direction:row;
   align-items: center;
   justify-content: center;
   box-shadow: #48473B 0px 5px 15px;
   margin-top:10px;
   justify-content: space-around;
   background-color:#ECEBDC;
   
}

  .container {
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin: 0 15px;
    
    margin-bottom:10px;
  }
  
  .send {
    text-decoration: none;
    color: black;
    text-align: center;
    margin-top:5px;
    border:1px solid black;
    border-radius:15px;
    padding:5px;
  }

  .tArea{
    color:black;
    
  }
  
  .black {
    color: black;
    margin-bottom:10px;
  }
  
  @media only screen and (max-width: 550px) {
    .footer {
      display: inline-block;
    }
    .contact{
      background-image: url("/public/images/mobal.jpg");
    }
    .container {
      width: 69%;
    }
    .bigContainer{
      flex-direction:column;
      width:80%;
    }
  }
  