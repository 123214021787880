.acoustic{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-bottom:50px;
    width:100vw;
    /* min-height:100vh; */
}

.cardWrap{
    width:75%;
    height:150px;
    background-size:cover;
    display:flex;
    align-items:center;
    justify-content:center;
    margin:20px auto;
    padding:1px;
    box-shadow: #48473B 0px 5px 15px;
    border-radius:10px; 
    position:relative;
    
}

.name{
    text-decoration:none;
    color:white;
    font-size:28px;
    line-height:40px;
    font-family:Sansita;
    z-index:15;
    width:100%;
    height:40px;
    background: rgba(29,46,50, 0.9) center;
}

.name:hover{
    cursor:pointer;
}

.darkenBg{
    height:100%;
    width:100%;
    background-color:grey;
    z-index:10;
    position:absolute;
    top:0;
    left:0;
    opacity:0.3;
    transition: opacity 0.4s;
}

.name:hover ~ .darkenBg{
    opacity:0.8;
}

@media only screen and (min-width: 550px) {

    .acoustic{
        width:90vw;
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        justify-content: space-around;
        margin:auto;
    }
    
    .cardWrap{
        width:25%;
        height:150px;
    }

}