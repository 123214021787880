.groupWrapper{
    text-align: center;
    /* background-image: url("/public/images/aleksa.jpg"); */
    padding: 0 15vw;
    /* background-image: url("/public/images/aleksa.jpg");
    background-size:contain; */
} 

.imageContainer{
    width:70vw;
    
}

.imageContainer img{
    height:auto;
    width:100%;
}

.videoContainer{
    margin:auto;
    width:70vw;
    height:200px;
}

/* .instruments{

} */

.invisible{
    display:none;
}

.name{
    color:#007662;
}

.mark{
    color:#48473B;
}

.infoValue{
    color:#007662;
    padding:13px;
}

.value{
    color:#007662;
    padding:5px;
}

.markWrapper{
    margin:10px auto;
}

.wrapper{
    border: 1px solid #48473B;
    position:relative;
    width:100%;
    margin:15px auto;
    background-color:#ECEBDC;
}
.pushImg{
    display:flex;
    flex-direction:column;
    align-items:center;
    
        }

.wrapperText{
    position:absolute;
    top:-10px;
    left:4px;
    height:20px;
    color:#48473B;
    background-color:#ECEBDC;
    padding:0 3px;
}

.socialMedia{
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
}

.icon:hover{
    cursor:pointer;
}

.videoContainer{
    margin-bottom:20px;
}

@media only screen and (min-width: 550px) {

    .groupWrapper{
        width:80vw;
        margin:auto;
    }

    .wrapper{
        margin:20px auto;
    }

    .imageContainer{
        width:35%;
        margin-right:40px;
        align-self: flex-start;
        margin-top:50px;
    }

    .pushImgOther{
width:65%;
    }

    .pushImg{
        display:flex;
        flex-direction:row;        
    }

    .videos{
        display:flex;
        flex-flow:row wrap;
    }

}