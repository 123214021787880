.errorMessage{
    font-size:10px;
    color:red;
    display:none;
}

.notify{
  font-style: italic;
  font-size:8px;
  color:darkgrey;
  margin-bottom:20px;
}

.error{
  font-size:10px;
    color:red;
}

input:invalid[focused="true"] ~ .errorMessage{
    display:block;
}
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.formStyle{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    margin-bottom:75px;
    background-color:#ECEBDC;
}

.fileInputContainer{
    margin-bottom:10px;
    position:relative;
  }
  
  .fileInput{
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 8px;
    font-size: 17px;
    color: #48473B;
    
  }
  
  .buttonLabel{
    display: inline-block;
    padding: 12px 18px;
    cursor: pointer;
    border-radius: 5px;
    background:transparent;
    font-size: 16px;
    box-shadow: #48473B 0px 5px 15px;
    color: #48473B;
  }

  .name{
    position:relative;
    margin-bottom:15px;
    width:50%;
    padding:5px;
    
  }


  
  .formField {
    border: 0;
    border-bottom: 2px solid #48473B;
    outline: 0;
    font-size: 15px;
    color: #007662;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    width:70%;
    margin:auto;
    padding-bottom:0;
  }
  .formField::placeholder {
      color:transparent;
  }
  
  .label {
    position: absolute;
    top: -7px;
    left:15%;
    display: block;
    transition: 0.2s;
    font-size: 9px;
    color: #007662;
  }
  
  .formField:placeholder-shown ~ .label {
      font-size: 15px;
      cursor: text;
      top: 9px;
      
    }
  
  .formField:focus ~ .label {
      position: absolute;
      top: -7px;
      display: block;
      transition: 0.2s;
      font-size: 9px;
      color: #00C9AF;
      font-weight:700;    
  }
  .formField:focus{
    padding-bottom: 6px;  
    font-weight: 500;
    border-width: 3px;
    border-image: linear-gradient(to right, #00C9AF,#007662);
    border-image-slice: 1;
  }

  .submit{
    margin-top:30px;
    padding:17px;
    border-radius:15px;
    font-size: 17px;
    background-color: #00C9AF;
    border:1px solid #00C9AF;
    font-size: 20px;
    font-weight: bold;
    color: #48473B;
    cursor:pointer;
    font-family:sansita;
    font-weight:bold;
  }