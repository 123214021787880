.reccomendationsWrapper {
   position:relative; 
   margin:10px 0;  
   margin-bottom:40px; 
   
}

.inputClass{
    width:150px;
    position:relative;
    z-index:150;
    border: 0;
  border-bottom: 2px solid #48473B;
  outline: 0;
  font-size: 15px;
  color: #007662;
  padding: 7px 15px;
  background: transparent;
  transition: border-color 0.2s;
  width:50%;
  margin:auto;
  padding-bottom:0;
}

.instruments ul{
   list-style:none;
   padding:10px;
   position:absolute;
   top:0;
   width:50%;
   background-color:#ECEBDC;
   opacity: .9;
  z-index:100;
}

.instruments ul li{
   
   border-bottom:1px solid #48473B;
  padding:8px;
}

.instruments ul li:hover{
   cursor:pointer
}

.suggestionClass{
width:250px;

margin:auto;
display:flex;
align-items: center;
justify-content: center;
} 
